import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Ionicons
import { addIcons } from 'ionicons';
import {
  cashOutline,
  cashSharp,
  bookmarkOutline,
  bookmarkSharp,
  schoolOutline,
  schoolSharp,
  documentOutline,
  documentSharp,
  airplaneOutline,
  airplaneSharp,
  bookOutline,
  bookSharp,
  timeOutline,
  timeSharp,
  informationCircleOutline,
  informationCircleSharp,
  homeOutline,
  homeSharp,
  heartSharp,
  chatbubbleSharp,
  moon,
  person,
  playOutline,
  cubeOutline,
} from 'ionicons/icons';

addIcons({
  'cash-outline': cashOutline,
  'cash-sharp': cashSharp,
  'bookmark-outline': bookmarkOutline,
  'bookmark-sharp': bookmarkSharp,
  'school-outline': schoolOutline,
  'school-sharp': schoolSharp,
  'document-outline': documentOutline,
  'document-sharp': documentSharp,
  'airplane-outline': airplaneOutline,
  'airplane-sharp': airplaneSharp,
  'book-outline': bookOutline,
  'book-sharp': bookSharp,
  'time-outline': timeOutline,
  'time-sharp': timeSharp,
  'information-circle-outline': informationCircleOutline,
  'information-circle-sharp': informationCircleSharp,
  'home-outline': homeOutline,
  'home-sharp': homeSharp,
  'heart-sharp': heartSharp,
  'chatbubble-sharp': chatbubbleSharp,
  'moon': moon,
  'person': person,
  'play-outline': playOutline,
  'cube-outline': cubeOutline,
});

import useFirebaseAuth from './hooks/firebase-auth';

const {authCheck} = useFirebaseAuth();


const app = createApp(App).use(IonicVue);

authCheck().then(async () => {
  app.use(router);
  await router.isReady();
  app.mount('#app');
});
