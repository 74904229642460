import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import useFirebaseAuth from "../hooks/firebase-auth";
import { CONFIG_ROUTE_SCORES } from "@/hooks/config-service";

const state = useFirebaseAuth();


const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/' + CONFIG_ROUTE_SCORES
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import ('../views/Login.vue')
  },
  {
    path: '/' + CONFIG_ROUTE_SCORES,
    name: 'Scores',
    component: () => import ('../views/ViewContainer.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import ('../views/ViewContainer.vue')
  },
  {
    path :'/:pathMatch(.*)*',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {

  // console.log('router.beforeEach route.to', to);
  // console.log('router.beforeEach state.user.value', state.user.value);
  if (state.user.value && (to.name === 'Login')) {
    next({path : '/' + CONFIG_ROUTE_SCORES, replace : true});
  } else if (!state.user.value && (to.name !== 'Login')) {
    // next({path : '/login', replace : true});
    next();
  } else {
    next();
  }
});


export default router
