<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-title class="nginfeed-title ion-padding-bottom">
              <router-link :to="'/' + CONFIG_ROUTE_SCORES" style="text-decoration: none; color: inherit;">
                nginfeed
              </router-link>

            </ion-title>

            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>


          <ion-list>
            <ion-item lines="none">
              <ion-icon slot="start" name="moon"></ion-icon>
              <ion-label>
                Dark Mode
              </ion-label>
              <ion-toggle id="themeToggle" slot="end" @ionChange="toggleDarkMode" :checked="darkMode"></ion-toggle>
            </ion-item>
          </ion-list>


        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonTitle, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, IonToggle } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import {useRoute, useRouter} from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, informationCircleOutline, informationCircleSharp, homeSharp, homeOutline } from 'ionicons/icons';
import firebase from "firebase";

import { CONFIG_ROUTE_SCORES } from "@/hooks/config-service";

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonIcon,
    IonItem, 
    IonLabel, 
    IonList, 
    IonTitle,
    IonMenuToggle,
    IonToggle
  },
  setup() {

    const darkMode = ref(false);
    setTimeout(() => {
      // document.body.classList.toggle('dark', true);
    }, 2000)

    function toggleDarkMode(v: any) {
      darkMode.value = !darkMode.value;
      document.body.classList.toggle('dark', darkMode.value);
    }


    const router = useRouter();
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Scores',
        url: '/' + CONFIG_ROUTE_SCORES,
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      }, {
        title: 'About',
        url: '/about',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleSharp,
      }
    ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    console.log('window.location.pathname', window.location.pathname)
    // const path = window.location.pathname.split('folder/')[1];
    const path = window.location.pathname.replace('/', '');
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    firebase.auth().onAuthStateChanged(async (_user) => {
      console.log('onAuthStateChanged CALLED in App.vue _user', _user);

      try {
        if (_user) {
          // await router.replace({ path: "/scores" });
          // console.log('stateCompanyId ABC', stateCompanyId.value);
          // const myCompanyId = await getUserCompany(_user.uid);
          // console.log('myCompanyId A', myCompanyId);

          /*if (!unsubscribe2) {
            unsubscribe2 = subscribeToUserEvents(_user);
          } else {
            unsubscribe2();
            unsubscribe2 = subscribeToUserEvents(_user);
          }*/

        } else {
          /*if (unsubscribe2) {
            unsubscribe2();
          }*/

        }
      } catch (error) {
        console.log('error', error);
        /*if (unsubscribe2) {
          unsubscribe2();
        }*/
      }




    });
    
    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages, 
      labels,
      darkMode,
      archiveOutline,
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : '',
      toggleDarkMode,
      CONFIG_ROUTE_SCORES,
    }
  }
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  /*border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);*/
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.nginfeed-title {
  font-size:2.0rem;
  font-weight: 100;
  text-align: center;
  font-family: 'Stint Ultra Expanded', cursive;
  /*font-family: 'Michroma', sans-serif;*/
}
</style>
