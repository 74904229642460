const FIREBASE_CONFIG = {
    apiKey: "AIzaSyAuzjdJXF_e1xWeubbdsV1P6DsWKZ9ievI",
    authDomain: "rt-webapp.firebaseapp.com",
    databaseURL: "https://rt-webapp.firebaseio.com",
    projectId: "rt-webapp",
    storageBucket: "rt-webapp.appspot.com",
    messagingSenderId: "6767689379",
    appId: "1:6767689379:web:413b1772ecc85dbcfdc916",
    measurementId: "G-4QEHXTX05L"
};

export default FIREBASE_CONFIG
