import { toRefs, reactive } from "vue";
import firebase from "firebase";
// Required for side-effects
import "firebase/firestore";

import FIREBASE_CONFIG from "./.env.firebase";
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

// initialize firebase, this is directly from the firebase documentation
// regarding getting started for the web
if (firebase.apps.length === 0) {
    firebase.initializeApp(FIREBASE_CONFIG);
}

const state = reactive<{ user: any; initialized: boolean; error: any }>({
    user: null,
    initialized: false,
    error: null,
});

export default function() {

    function getPrimaryCompanyFromUserCompanyObj(obj: any) {
        if (!obj) {
            return '';
        }
        let companyId = '';
        for (const tmpCompanyId in obj) {
            if (obj[tmpCompanyId].primary) {
                companyId = tmpCompanyId;
            }
        }

        if (!companyId) {
            for (const tmpCompanyId in obj) {
                companyId = tmpCompanyId;
                break;
            }
        }

        return companyId;
    }

    const getUserCompany = async (uid: string) => {
        let companyId = '';

        try {
            if (uid) {
                const stdRef = await firebase.firestore().doc('user_company/' + uid).get();
                const obj: any = stdRef.data();
                // console.log('obj', obj);
                companyId = getPrimaryCompanyFromUserCompanyObj(obj);

                // console.log('getUserCompany companyId', companyId);
            }
        } catch (error) {
            console.log('getUserCompany error', error);
        }
        return companyId;
    };


    const loginWithProvider = async () => {
        try {
            const myProvider = new GoogleAuthProvider();
            const res = await firebase.auth().signInWithPopup(myProvider);
            if (res && res.user) {
                state.user = res.user;
                state.error = null;
                return res.user;
            }

        } catch (error) {
            state.error = error;
            console.log('state.error', state.error);
            // throw error;
        }
    };

    const logout = async () => {
        try {
            // console.log('logout');
            await firebase.auth().signOut();
            state.user = null;
            // state.stateCompanyId = '';
            // console.log('logout', user);
        } catch (error) {
            console.log('error', error);
        }

    };

    // RUN AT STARTUP
    const authCheck = () => {
        return new Promise((resolve) => {
            !state.initialized &&
            firebase.auth().onAuthStateChanged(async (_user) => {
                console.log('onAuthStateChanged user CALLED in firebaseAuth');
                if (_user) {
                    state.user = JSON.parse(JSON.stringify(_user));
                    // state.user.email = 'bschall@gsconsultingllc.com';
                    // state.user.displayName = 'Brandon Schall';
                    // console.log('onAuthStateChanged firebaseAuth user', _user);
                } else {
                    state.user = null;
                }
                state.initialized = true;
                // console.log(_user);
                resolve(true);
            });
        });
    };

    return {
        ...toRefs(state),
        // FUNCTIONS
        loginWithProvider,
        logout,
        authCheck,
        getPrimaryCompanyFromUserCompanyObj,
    };
}
