
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonTitle, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, IonToggle } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import {useRoute, useRouter} from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, informationCircleOutline, informationCircleSharp, homeSharp, homeOutline } from 'ionicons/icons';
import firebase from "firebase";

import { CONFIG_ROUTE_SCORES } from "@/hooks/config-service";

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonIcon,
    IonItem, 
    IonLabel, 
    IonList, 
    IonTitle,
    IonMenuToggle,
    IonToggle
  },
  setup() {

    const darkMode = ref(false);
    setTimeout(() => {
      // document.body.classList.toggle('dark', true);
    }, 2000)

    function toggleDarkMode(v: any) {
      darkMode.value = !darkMode.value;
      document.body.classList.toggle('dark', darkMode.value);
    }


    const router = useRouter();
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Scores',
        url: '/' + CONFIG_ROUTE_SCORES,
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      }, {
        title: 'About',
        url: '/about',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleSharp,
      }
    ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    console.log('window.location.pathname', window.location.pathname)
    // const path = window.location.pathname.split('folder/')[1];
    const path = window.location.pathname.replace('/', '');
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    firebase.auth().onAuthStateChanged(async (_user) => {
      console.log('onAuthStateChanged CALLED in App.vue _user', _user);

      try {
        if (_user) {
          // await router.replace({ path: "/scores" });
          // console.log('stateCompanyId ABC', stateCompanyId.value);
          // const myCompanyId = await getUserCompany(_user.uid);
          // console.log('myCompanyId A', myCompanyId);

          /*if (!unsubscribe2) {
            unsubscribe2 = subscribeToUserEvents(_user);
          } else {
            unsubscribe2();
            unsubscribe2 = subscribeToUserEvents(_user);
          }*/

        } else {
          /*if (unsubscribe2) {
            unsubscribe2();
          }*/

        }
      } catch (error) {
        console.log('error', error);
        /*if (unsubscribe2) {
          unsubscribe2();
        }*/
      }




    });
    
    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages, 
      labels,
      darkMode,
      archiveOutline,
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : '',
      toggleDarkMode,
      CONFIG_ROUTE_SCORES,
    }
  }
});
