export const SPORT_STAT_ORIGINAL_GOAL = 'goal';
export const SPORT_STAT_ORIGINAL_ASSIST = 'assist';
export const SPORT_STAT_ORIGINAL_GROUNDBALL = 'groundball';
export const SPORT_STAT_ORIGINAL_SAVE = 'save';
export const SPORT_STAT_ORIGINAL_GROUNDBALL_ABBREV = 'GB';

export const SPORT_STAT_FOOTBALL_RUSHING = 'rushing';
export const SPORT_STAT_FOOTBALL_PASSING = 'passing';
export const SPORT_STAT_FOOTBALL_RECEIVING = 'receiving';
export const SPORT_STAT_FUMBLE_RECOVER = 'fumble_recover';
export const SPORT_STAT_INTERCEPTION = 'interception';
export const SPORT_STAT_FG = 'fg';
export const SPORT_STAT_FG_ABBREV = 'FG';
export const SPORT_STAT_ORIGINAL_GOALS = 'goals';
export const SPORT_STAT_ORIGINAL_ASSISTS = 'assists';
export const SPORT_STAT_ORIGINAL_POINTS = 'points';
export const SPORT_STAT_BASKETBALL_ASSISTS = 'Assists';
export const SPORT_STAT_BASKETBALL_POINTS = 'Points';
export const SPORT_STAT_BASKETBALL_REBOUNDS = 'Rebounds';
export const SPORT_BASKETBALL_SCORE_TYPE_ASSIST = 'assist';
export const SPORT_BASKETBALL_SCORE_TYPE_REBOUND = 'rebound';
export const SPORT_BASKETBALL_SCORE_TYPE_FREE_THROW = 'ft';
export const SPORT_BASKETBALL_SCORE_TYPE_TWO_POINT = '2-pt';
export const SPORT_BASKETBALL_SCORE_TYPE_THREE_POINT = '3-pt';

export const CONFIG_ROUTE_SCORES = 'scores';
